<template>
  <div class="row m-0 px-lg-4">
    <div class="col-12 px-lg-5">
      <div class="row m-0">
        <!--ALL JOURNEYS PAGE-->
        <div class="col-12 p-0" v-if="journeysActivePage === 'all'">
          <all-journeys></all-journeys>
        </div>
        <!--NEW ENQUIRY PAGE-->
        <div class="col-12 p-0" v-else-if="journeysActivePage === 'bookNextJourney'">
          <new-enquiry></new-enquiry>
        </div>
        <!--NEW ENQUIRY PAGE-->
        <!--ITINERARY PAGE-->
        <div class="col-12 p-0" v-else-if="journeysActivePage === 'itinerary'">
          <!-- This is the itinerary page -->
        </div>
        <!--END ITINERARY PAGE-->
        <!--HISTORY PAGE-->
        <div class="col-12 p-0" v-else-if="journeysActivePage === 'history'">
          <!-- This is the history page -->
        </div>
        <!--END HISTORY PAGE-->
        <!--BLANK PAGE-->
        <div class="col-12 px-0 pt-5 mt-md-5 mt-4" v-else>
          <div class="row m-0 d-flex d-lg-none">
            <div class="col-6">
              <button class="btn w-100" @click="isActive('bookNextJourney')">Book my next journey</button>
            </div>
            <div class="col-6">
              <button class="btn w-100" @click="isActive('itinerary')">Itinerary</button>
            </div>
            <div class="col-6">
              <button class="btn w-100" @click="isActive('history')">History</button>
            </div>
          </div>
        </div>
        <!--END BLANK PAGE-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'JourneysPage',
  components: {
    'new-enquiry': () => import('@/components/NewEnquiryForm'),
    'all-journeys': () => import('@/components/AllJourneys')
  },
  props: ['journeysActivePage'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
<style scoped>
</style>
